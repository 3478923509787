import React from 'react';
import { BrowserRouter as Router, Route, Routes,Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './styles/GlobalStyle';
import { theme } from './styles/theme';
import Footer from './components/Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';
import styled from 'styled-components';
import Modal from 'react-modal';
import Myna from './pages/Myna';
import Features from './components/Features';
import Chatbot from './components/Chatbot';
import Privacy from './pages/Privacy';
import Dashboard from './pages/Dashboard';

Modal.setAppElement('#root');

const AppContainer = styled.div`
  /* No need for min-height if your footer is pinned */
`;

const Content = styled.main`
  flex: 1;
  padding-bottom: 80px; /* Adjust based on your footer's height */
`;
const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <GlobalStyle />
      <AppContainer>
        <Content>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/features" element={<Features />} />
            <Route path="/myna" element={<Myna />} />
            <Route path="/chatbot" element={<Chatbot />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </Content>
        <Footer />
      </AppContainer>
    </Router>
  </ThemeProvider>
);

export default App;
